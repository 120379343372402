import { DotPadLine } from "./DotPadLine";

class DotPadSendModule {
    constructor(dotPad) {
        this.dotPadLineList = [];
        this.dotCommandSendReady = true;
        this.sendTime = new Date();
        this.sendCnt = 0;
        this.dotPadLine = null;
        this.restart = false;
        this.currentIndex = 0;
        this.isFunctionRunning = false;
        this.dotPad = dotPad;
    }

    // 라인목록 초기화
    clearDotPadLine(){
        this.dotPadLineList = [];
    }

    // 320패드 라인목록 세팅
    addDotPad320Line() {
        // 20셀 세팅
        this.dotPadLineList.push(new DotPadLine(0, 600, 20));

        // 300셀 세팅
        for (let i = 1; i < 11; i++) {
            this.dotPadLineList.push(new DotPadLine(i, 600, 30));
        }
    }

    // 해당 라인에 커맨드 세팅
    setDotPadLineCommand(line, seqNum, startCellIndex, sendData) {
        this.dotPadLineList[line].setCommand(seqNum, startCellIndex, sendData);
    }

    // 발송준비 세팅
    setDotCommandSendReady(ready) {
        this.dotCommandSendReady = ready;
    }

    async refresh() {
        for (let i = 0; i < this.dotPadLineList.length; i++) {
            this.dotPadLineList[i].refresh();
        }

        await this.sendCommand();
    }

    async sendCommand() {
        if (!this.isFunctionRunning) {
            this.isFunctionRunning = true;
            this.currentIndex = 0;

            const processNextLine = async () => {
                if (this.currentIndex < this.dotPadLineList.length) {
                    // 재시작일경우 값 초기화
                    if (this.restart) {
                        this.currentIndex = 0;
                        this.restart = false;
                        this.dotCommandSendReady = true;
                    } else {
                        this.dotPadLine = this.dotPadLineList[this.currentIndex];

                        // 발송해야하는지 확인
                        if (this.dotPadLine.getRequestReady() && this.dotCommandSendReady) {
                            // 발송
                            //console.log("발송");
                            await this.dotPad.writeCmdTest(this.dotPadLine.sendCommand());
                            this.dotCommandSendReady = false;

                            // 시간체크 시작
                            this.sendTime = new Date();
                            this.sendCnt = 0;
                        }
                        // ACK 수신 대기중
                        else if (!this.dotPadLine.getReceiveAck()) {
                            // 시간체크
                            const nowTime = new Date() - this.sendTime;
                            if (nowTime > this.dotPadLine.getRequestTime()) {
                                // 재요청
                                if (this.sendCnt < 3) {
                                    // 재발송
                                    await this.dotPad.writeCmdTest(this.dotPadLine.sendCommand());
                                    this.sendTime = new Date();
                                    this.sendCnt += 1;
                                } else {
                                    // 수신받은 것으로 처리
                                    this.dotPadLine.setReceiveAck(true);
                                    this.dotCommandSendReady = true;
                                }
                            }
                        } else if (this.dotCommandSendReady) {
                            this.currentIndex += 1;
                        }
                    }

                    setTimeout(processNextLine, 10); // 0.01초 지연 후 다음 반복 실행
                } else {
                    this.isFunctionRunning = false;
                }
            };

            await processNextLine();
        } else {
            this.restart = true;
        }
    }

    // Ack 세팅
    setDotPadLineReceiveAck(line, receiveAck) {
        const dotPadLine = this.dotPadLineList[line];
        if(dotPadLine){
            dotPadLine.setReceiveAck(receiveAck);
        }
    }
}

export {
    DotPadSendModule
}
