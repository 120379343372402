/*
 * =====================================================================
 * 작 업 명  : 전역함수
 * 파 일 명  : global.js
 * 변경이력
 * 2022-06-17 / 정태화 / 최초작성
 * 2023-02-10 / 이성준 / 업체번호 전역변수 추가
 * 2023-10-27 / 이성준 / 유저 권한 스키마 변경 dot-saas-vision ➡️ dot-saas-user
 * =====================================================================
 */
import { $session, $router, $site } from "dot-framework";
import store from "@/store/index.js";

export default
{
    // 통합인증 및 메뉴/권한 설정
    setSession()
    {
        // (1) 통합인증 연동 설정
        let siteNo = process.env.VUE_APP_SITE_NO;
        let ssoDomain = process.env.VUE_APP_SSO_DOMAIN;
        let callbackUrl = process.env.VUE_APP_CALLBACK_URL;

        $session.setSiteNo(siteNo);
        $session.setSsoDomain(ssoDomain);
        $session.setCallbackUrl(callbackUrl);

        // (2) 업체번호 설정 및 메뉴/권한 연동
        if (process.env.VUE_APP_IS_ELECTRON == "true") {
            const compNo = "C220512001"; // 업체번호(주식회사닷) 설정
            $session.setCompNo(compNo);
            store.commit("setCompNo", compNo);
            store.dispatch("setMenuList"); // 메뉴/권한 연동
       } else {
            // 도메인에서 업체번호 조회
            let domain = $router.getDomain();
            $site.setCompNoBydomain(domain, function(compNo) {
                $session.setCompNo(compNo); // 업체번호 설정
                store.commit("setCompNo", compNo);
                store.dispatch("setMenuList"); // 메뉴/권한 연동
            });
       }
    },
    isLogin()
    {
        return $session.isLogin();
    },
    isDotAdmin()
    {
        const roles = $session.getUserRoles('dot-saas-user');

        return !!(roles && roles.length && roles.includes('DOT-ADMIN'));
    }
}
