<template>
    <div class="container-fluid">
        <header class="d-flex flex-wrap bg-white align-items-center justify-content-center justify-content-md-between">
            <router-link to="/">
                <img src="/theme/responsive_dot/include/img/dot_logo.svg" width="100" height="25" alt="Dot Incorporation Logo">
            </router-link>
            <!-- 상단 메뉴 영역 ===================================== -->
            <TopMenu></TopMenu>
            <!-- // 상단 메뉴 영역 ================================== -->

            <!-- 로그인 처리 영역 ==================================== -->
            <SignInOut ref="sign-in-out"></SignInOut>
            <!-- // 로그인 처리 영역 ================================= -->
        </header>
    </div>
    <div class="row">
        <!-- 컨텐츠 표시 영역 ======================================== -->
        <router-view></router-view>
        <!-- //컨텐츠 표시 영역 ====================================== -->

        <!-- 하단 표시 영역 ========================================= -->
        <!-- <Footer></Footer> -->
        <!-- // 하단 표시 영역 ====================================== -->
    </div>
</template>

<script>
	import TopMenu from "./inc/TopMenu";
	import SignInOut from "./inc/SignInOut";
	// import Footer from "./inc/Footer";
	import { router } from "@/routes/index.js";

	export default
	{
		components:
		{
			TopMenu,
			SignInOut,
			// Footer
		},
        provide()
        {
            return {
                signIn: this.signIn,
            }
        },
		data() {
			return {
				colLeft: "col-md-3 col-lg-2",
				colRight: ""
			}
		},
		methods:
		{
			// 루트 페이지로 이동
			goRoot()
			{
				router.push({path: "/"});
			},
            signIn()
            {
                this.$refs["sign-in-out"].signIn();
            }
		}
	}
</script>

<style scoped>
.container-fluid {
    padding: 10px 2%;
    background: #fff;
}
.row {
    /* padding: 10px 2%; */
}
</style>
