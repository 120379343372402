<template>
  <nav class="justify-content-center">
    <ul class="nav nav-pills" role="menu">
      <template v-for="menu in $store.getters.MENU_LIST_PARENT('000000')" :key="menu.MENU_ID">
        <li v-if="menu.MENU_ID !== 'M00003'" class="nav-item">
          <router-link
            :to="{ path: menu.MENU_URL }"
            class="nav-link p-0 position-relative"
            role="menuitem"
            @click="topMenuClick(menu)"
          >
            {{ $t(menu.MENU_NAME) }}
          </router-link>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
  import { $site } from "dot-framework";

  export default
  {
    data() {
      return {
        topMenuId: ""
      }
    },
    methods:
    {
      // 상단 메뉴 클릭
      topMenuClick(menu) {
        this.topMenuId = menu.MENU_ID;
        // (1) 상위메뉴ID store에 저장
        this.$store.commit("setTopMenuId", this.topMenuId);
        // (2) 첫번째 메뉴 처리
        const topMenuFirst = $site.getTopMenuFirst(menu, this.$store);
        this.$store.commit("setSelectedMenu", topMenuFirst.MENU_ID);
      },
    },
  }
</script>

<style scoped>
.nav {
  gap: 3.1rem;
}
.nav-link {
  text-decoration: none;
  color: #767474;
  font-size: 1.5rem;
}
.nav-link:hover {
  text-decoration: none;
  opacity: 0.8
}
/* .nav-link.active {
  color: #EA5414;
  background: unset;
} */
.router-link-active {
  color: #EA5414;
  background: unset;
}
</style>
