/*
 * =====================================================================
 * 작 업 명  :
 * 파 일 명  : drive.js
 * 변경이력
 * 2023-01-05 / 이성준 / 최초작성
 * =====================================================================
 */
import {$axios, $session} from "dot-framework";
import DTMSCanvas from "@/js/DTMSCanvas";

const canvasWidth = 99;
const canvasHeight = 99;
/**
 * @type {Readonly<{CARD: symbol, LIST: symbol}>}
 */
const CLIPBOARD_MODE = Object.freeze({
    COPY: Symbol("COPY"),
    CUT: Symbol("CUT")
});
const VIEW_MODE = Object.freeze({
    CARD: Symbol("CARD"),
    LIST: Symbol("LIST"),
});

const state = () => ({
    clipboard: [], // 선택한 행 복사(클립보드)
    clipboardMode: null,
    driverKind: "P",
    items: [],    // Private Drive
    viewMode: VIEW_MODE.CARD
});

// getters
const getters = {
    clipboard: (state) => {
        return state.clipboard;
    },
    clipboardMode: (state) => {
        return state.clipboardMode;
    },
    driverKind: (state) => {
        return state.driverKind;
    },
    filteredItems: (state) => (textFilter) => {
        //FIXME: metacharacter(?:^|\\W)
        const regex = new RegExp(textFilter, 'i');

        return state.items.filter(item => regex.test(item.FILE_NAME));
    },
    isPrivate: (state) => {
        return state.driverKind === "P";
    },
    isPublic: (state) => {
        return state.driverKind === "D";
    },
    items: (state) => {
        return state.items;
    },
    viewMode: (state) => {
        return state.viewMode;
    },
};

// mutations
const mutations = {
    clearClipboard(state) {
        state.clipboard = [];
    },
    pushClipboard(state, clipboard) {
        state.clipboard.push(clipboard);
    },
    setClipboardMode(state, mode) {
        state.clipboardMode = CLIPBOARD_MODE[mode];
    },
    setDriveKindStatus(state, status) {
        state.driverKind = status;
    },
    setItems(state, items) {
        state.items = items;
    },
    setViewMode(state, status) {
        state.viewMode = VIEW_MODE[status];
    }
};

// actions
const actions = {
    /**
     * @param context
     * @param payload
     * @param {string} payload.parentGroupNo
     * @param rootState
     * @param rootGetters
     * @returns {Promise<*>}
     */
    async fetchDtmsGroupList(context, payload = {parentGroupNo: ""}, rootState, rootGetters) {
        const params = {
            COMP_NO: $session.getCompNo() || rootGetters.COMP_NO,
            PARENT_GROUP_NO: payload.parentGroupNo,
            DRIVER_KIND: context.state.driverKind,
            USER_NO: $session.getUserNo() !== "GUEST" ? $session.getUserNo() : ""
        };
        // 값이 없는 속성 제거
        Object.keys(params)
            .map(key => {
                if (!params[key]) {
                    delete params[key];
                }
            });
        const query = Object.keys(params).length !== 0 ? `?${new URLSearchParams(params).toString()}` : "";
        const url = `/vision-app/v1/dtms/groups${query}`;
        const promise = $axios.get(url);
        const response = await promise;

        if (response.status === 401) {
            setTimeout(async () => await context.dispatch("reloadItems", payload), 1000);
        }

        context.commit("setItems", response.data.items);

        return promise;
    },
    async reloadItems(context, payload) {
        await context.dispatch("fetchDtmsGroupList", payload);

        switch (context.state.viewMode) {
            case VIEW_MODE.CARD:
                await context.dispatch("createDTMSCanvasOfCardTypeItems");
                break;
        }
    },
    /**
     * card형 목록의 DTMS 썸네일 생성
     * @param context
     */
    createDTMSCanvasOfCardTypeItems(context) {
        context.state.items?.forEach((obj) => {
            if (obj.FILE_TYPE === 'FILE') {
                const DTMSJson = obj.DTMS_JSON;

                if (DTMSJson) {
                    const pixelArr = DTMSCanvas.getPixelArray(DTMSJson.items[0]);
                    const params = {
                        pixelArr: pixelArr,
                        canvasId: `canvas-${obj.FILE_KEY}-0`,
                        canvasWidth,
                        canvasHeight
                    };

                    const canvas = DTMSCanvas.createCanvas(params.canvasId, params.canvasWidth, params.canvasHeight);
                    canvas.add(DTMSCanvas.drawTactileDataOnTheCanvas(params.pixelArr, params.canvasWidth, params.canvasHeight));
                }
            }
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
