import { createWebHashHistory, createWebHistory, createRouter } from "vue-router";
import { $session } from "dot-framework";
import routerLogin from "@/routes/login.js";
import routerTest from "@/routes/test.js";

const routes = [
    {
        name: "root",
        path: "/",
        component: () => import(/* webpackChunkName: "main" */ "@/views/main/Index.vue"),
        meta: {layout: "WideLayout"}
    },
    {
        name: "canvas",
        path: "/canvas",
        component: () => import(/* webpackChunkName: "dot-canvas" */ /* webpackPrefetch: true */ "@/views/canvas/DotCanvas.vue"),
        meta: {layout: "WideLayout"}
    },
    {
        name: "cloud",
        path: "/cloud",
        alias: "/drive",
        component: () => import(/* webpackChunkName: "dot-cloud" */ "@/views/drive/DriveList.vue"),
        meta: {layout: "DriveLayout"}
    },
    {
        name: "support",
        path: "/support",
        beforeEnter: () => window.location.replace(process.env["VUE_APP_DOT_SUPPORT_APP"])
    },
    ...routerLogin,
    ...routerTest
];

const whiteList = [
    "login",
    "login-callback",
    "root",
];

const router = createRouter({
    history: process.env.VUE_APP_IS_ELECTRON === "true" ? createWebHashHistory() : createWebHistory(),
    routes,
});

router.beforeEach((to) => {
    if (
        // make sure the user is authenticated
        !$session.isLogin() &&
        // ❗️ Avoid an infinite redirect
        !whiteList.includes(to.name)
    ) {
        // If there is no refresh token, go to login page
        if (!$session.getRefreshToken()) {
            // redirect the user to the login page
            return { name: "login" };
        }

        $session.callRefreshToken()
            .catch(error => {
                // if the refresh token is invalid, go to login page
                if (error.response.status >= 400 && error.response.status < 500) {
                    return { name: "login" };
                }
            });
    }
})

export {router};
