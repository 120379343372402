<template>
    <div class="d-flex text-end gap-2">
        <div v-show="isLogin" class="dropdown">
            <a href="#" class="dropdown-toggle d-inline-block rounded-circle text-center"
               id="dropdownUser1" ref="dropdown-user-1" data-bs-toggle="dropdown" aria-expanded="false"
               @click="toggleDropdown">{{ userInitial }}</a>
            <ul class="dropdown-menu text-small bg-dot-gray" aria-labelledby="dropdownUser1">
                <li><a class="dropdown-item" href="#" @click="accountInfo()">{{ $t('프로필') }}</a></li>
<!--                    <li><hr class="dropdown-divider"></li>-->
                <li><a class="dropdown-item" href="#" data-bs-target="#connectDotPadDialog" data-bs-toggle="modal">{{ $t('닷패드연결') }}</a></li>
<!--                    <li><hr class="dropdown-divider"></li>-->
                <li><a class="dropdown-item" :href="pdfLink" :download="$t('사용자 설명서')">{{ $t('사용자 설명서') }}</a></li>
                <li><a class="dropdown-item" href="#" @click="signOut()">{{ $t('로그아웃') }}</a></li>
            </ul>
        </div>
        <button v-show="!isLogin" class="btn" @click="signIn()">{{ $t('로그인') }}</button>
        <div class="input-group w-auto">
            <span>Language</span>
            <select class="form-select" id="locale" v-model="$i18n.locale">
<!--                <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>-->
                <option value="ko">한국어</option>
                <option value="cz">Czech</option>
                <option value="de">German</option>
                <option value="en">Eng</option>
                <option value="pt">Português</option>
                <option value="kz">қазақ</option>
            </select>
            <div class="selectArrow" style="right: 44px;">
                <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.66804 0.821604L10.1533 9.30688L18.6386 0.821603" stroke="#44403F" stroke-width="2"/>
                </svg>
            </div>
        </div>
        <div class="input-group w-auto">
            <span>Braille</span>
            <braille-option/>
            <div class="selectArrow">
                <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.66804 0.821604L10.1533 9.30688L18.6386 0.821603" stroke="#44403F" stroke-width="2"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import {$session} from "@/js/dot-framework-vue-1.0.2.js";
import Dropdown from "bootstrap/js/dist/dropdown";
import {router} from "@/routes";
import BrailleOption from "@/layouts/inc/BrailleOption.vue";

export default {
  name: "SignInOut",
    components: {
      BrailleOption,
    },
  data() {
    return {
      isLogin: $session.isLogin(),
      userInitial: "D",
      dropdown: null,
      isDropdownHidden: true,
      pdfLink: require("@/assets/manual/DPA320A 닷 캔버스 웹 가이드_Kor_230217.pdf")
    }
  },
  mounted() {
    this.userInitial = $session.getUserInfo().NAME?.substring(0, 1) || "D";
    // FIXME: login 했을 때 생성하도록 변경
    const el = this.$refs["dropdown-user-1"];
    this.dropdown = new Dropdown(el);
  },
  unmounted() {
    this.dropdown = null;
  },
  methods: {
    toggleDropdown() {
      this.dropdown.toggle();
    },
    // 로그인 처리
    signIn() {
      router.push({path: "login"});
    },
    // 회원정보 URL 이동
    accountInfo() {
      const ssoDomain = $session.getSsoDomain();
      const siteNo = $session.getSiteNo();
      const compNo = $session.getCompNo();
      const accessToken = $session.getAccessToken();
      const refreshToken = $session.getRefreshToken();
      const callback = `${window.location.protocol}//${window.location.host}/login-callback`;
      const accountInfoUrl = `${ssoDomain}/basic?s=${siteNo}&c=${compNo}&a=${accessToken}&r=${refreshToken}&cb=${callback}`;
      //const accountInfoUrl = $session.getAccountInfoUrl();
      window.open(accountInfoUrl);
    },
    // 로그아웃
    signOut() {
      this.isLogin = false;
      $session.signOut();
      // 무한 리다레이션 방지를 위한 루트페이지로 이동
      router.push({name: "root"})
    }
  },
}
</script>

<style scoped>
.dropdown-toggle {
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  background-color: #767474;
  color: white !important;
  font-size: 2rem;
}

.dropdown-toggle::after {
  all: unset;
}

.dropdown-menu {
  border: none;
  margin-top: 10px !important;
}

.dropdown-item:focus, .dropdown-item:hover {
  background: #ea5414 !important;
}

.dropdown-item {
  color: #f7f7f7;
  font-size: 20px;
}

.input-group span {
  font-size: 1rem;
  color: #767474;
  font-weight: bold;
}

.input-group > select {
  width: 100%;
}

.form-select {
  border: none;
  padding: 8px 0;
  font-weight: bold;
  width: 100%;
  appearance: none;
  background: transparent;
  z-index: 3;
}
.selectArrow {
  position: absolute;
  top: 33px;
  right: 0px;
  cursor: pointer;
  z-index: 1;
}
</style>
