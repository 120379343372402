<template>
  <component :is="layout" ref="layout"/>
</template>
<script>
import {defineAsyncComponent} from 'vue'

/**
 * 폰트 설정
 */
import '@/assets/css/font.css';
import FontFaceObserver from 'fontfaceobserver';

import '@/assets/css/common.css';

/**
 * 레이아웃 설정
 */
import WideLayout from './layouts/WideLayout.vue';

const DefaultLayout = defineAsyncComponent(() => import("@/layouts/DefaultLayout.vue"));
const BlankLayout = defineAsyncComponent(() => import ("@/layouts/BlankLayout.vue"));
const DriveLayout = defineAsyncComponent(() => import ("@/layouts/DriveLayout.vue"));

export default {
  name: 'App',
  components:
      {
        DefaultLayout,
        BlankLayout,
        WideLayout,
        DriveLayout,
      },
  data() {
    return {
      layout: null
    }
  },
  created() {
    this.setFont();
    this.setHTMLLangAttribute();
    this.initLocale();
  },
  beforeMount() {
    document.getElementById("spinner").classList.add('d-none');
  },
  watch: {
    $route(to) {
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout
      } else {
        this.layout = "User"
      }
    },
    '$i18n.locale'() {
      this.setHTMLLangAttribute();
    }
  },
  methods: {
    /**
     * 다국어 설정
     */
    setHTMLLangAttribute() {
      document.documentElement.setAttribute('lang', this.$i18n.locale);
    },
    initLocale() {
      const defaultLanguage = "en";
      const lang = navigator.language || navigator.userLanguage;
      const code = lang.substring(0, 2);
      const availableLocales = this.$i18n.availableLocales.includes(code);

      return this.$i18n.locale = availableLocales ? code : defaultLanguage;
    },
    /**
     * 폰트 설정
     * @see https://fonts.google.com/knowledge/glossary/fout
     */
    setFont() {
      const font = new FontFaceObserver('Helvetica Neue');

      font.load().then(function () {
        document.body.classList.add("fonts-loaded");
      }).catch(function () {
        console.log('Helvetica Neue failed to load.');
      });
    }
  }
}
</script>

<style scoped>

</style>
