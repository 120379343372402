import { createStore } from "vuex";
import site from "@/store/site.js";
import { $menu } from "dot-framework";
import braille from '@/store/modules/braille.js';
import canvasPage from '@/store/modules/canvas/page.js';
import drive from '@/store/modules/drive.js';

export default createStore({
  modules: {
    site,
    $menu,
    canvasPage,
    braille,
    drive,
  }
});
