import {DotpadSDK} from "@/components/dtms-editor-v1.7/js/DotpadSDK.js";
import {fabric} from "fabric";

const dotpadSDK = new DotpadSDK();
const NUMBER_OF_HORIZONTAL_PINS = 60;
const NUMBER_OF_VERTICAL_PINS = 40;

function getPixelArray(item) {
    const graphicData = item.graphic.data; // 600자 Hex String
    const byteArr = dotpadSDK.hexToBytes(graphicData); // 300 Bytes Array
    const pixelArr = [];

    for (let i = 0; i < byteArr.length; i++) {
        const start_index = Math.floor(i / 30) * 60 * 4 + (i % 30) * 2;
        pixelArr[start_index] = !!(byteArr[i] & (0x01 << 0));
        pixelArr[start_index + 60] = !!(byteArr[i] & (0x01 << 1));
        pixelArr[start_index + 120] = !!(byteArr[i] & (0x01 << 2));
        pixelArr[start_index + 180] = !!(byteArr[i] & (0x01 << 3));
        pixelArr[start_index + 1] = !!(byteArr[i] & (0x01 << 4));
        pixelArr[start_index + 61] = !!(byteArr[i] & (0x01 << 5));
        pixelArr[start_index + 121] = !!(byteArr[i] & (0x01 << 6));
        pixelArr[start_index + 181] = !!(byteArr[i] & (0x01 << 7));
    }

    return pixelArr;
}

// 캔버스 생성
function createCanvas(canvasId, canvasWidth, canvasHeight) {
    const staticCanvas = new fabric.StaticCanvas(canvasId);
    staticCanvas.setWidth(canvasWidth);
    staticCanvas.setHeight(canvasHeight);

    return staticCanvas;
}

// 캔버스에 그리드 그리기
function drawGridOnTheCanvas(canvasWidth, canvasHeight) {
    const gridLines = [];
    const gridOptions = {type: 'line', stroke: '#cccccc', selectable: false};

    for (let row = 0; row < NUMBER_OF_VERTICAL_PINS; row++) {
        const y = (row / NUMBER_OF_VERTICAL_PINS) * canvasHeight;
        gridLines.push(new fabric.Line([0, y, canvasWidth, y], gridOptions));
    }

    for (let column = 0; column < NUMBER_OF_HORIZONTAL_PINS; column++) {
        const x = (column / NUMBER_OF_HORIZONTAL_PINS) * canvasWidth;
        gridLines.push(new fabric.Line([x, 0, x, canvasHeight], gridOptions));
    }

    return new fabric.Group(gridLines, {
        selectable: false,
        evented: false
    });
}

// 캔버스에 촉각 정보 그리기
function drawTactileDataOnTheCanvas(pixelArr, canvasWidth, canvasHeight) {
    const pinW = canvasWidth / NUMBER_OF_HORIZONTAL_PINS;
    const pinH = canvasHeight / NUMBER_OF_VERTICAL_PINS;
    const array = [];
    let index = 0;

    for (let i = 0; i < NUMBER_OF_VERTICAL_PINS; i++) {
        for (let j = 0; j < NUMBER_OF_HORIZONTAL_PINS; j++) {
            if (pixelArr[index]) {
                const pin = new fabric.Rect({
                    fill: 'black',
                    left: pinW * j,
                    top: pinH * i,
                    width: pinW,
                    height: pinH,
                });
                array.push(pin);
            }
            index++;
        }
    }

    return new fabric.Group(array);
}

export default {
    createCanvas,
    drawGridOnTheCanvas,
    drawTactileDataOnTheCanvas,
    getPixelArray,
}
