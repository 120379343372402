class DotPadLine {
    constructor(lineId, requestTime, numberCellColumns) {
        this.lineId = lineId;
        this.startCellIndex = 0;
        this.sendData = "";
        this.seqNum = "";
        this.lineData = "00".repeat(numberCellColumns);
        this.requestReady = false;
        this.requestTime = requestTime;
        this.receiveAck = true;
        this.numberCellColumns = numberCellColumns;
    }

    getLineId() {
        return this.lineId;
    }

    getRequestReady() {
        return this.requestReady;
    }

    getReceiveAck() {
        return this.receiveAck;
    }

    setReceiveAck(receiveAck) {
        this.receiveAck = receiveAck;
    }

    getRequestTime() {
        return this.requestTime;
    }

    refresh() {
        this.requestReady = true;
        this.receiveAck = true;
        this.sendData = this.lineData;
    }

    checksum(test_data) {
        let check = 0xA5;

        for (let i = 0; i < test_data.length; i++) {
            check = check ^ test_data[i]
        }

        return ('0' + (check & 0xFF).toString(16)).slice(-2);
    }

    decimalToHex(d, padding = 2) {
        return Number(d).toString(16).padStart(padding, "0");
    }

    hexToBytes(hex) {
        const bytes = [];
        for (let c = 0; c < hex.length; c += 2)
            bytes.push(parseInt(hex.substr(c, 2), 16));
        return bytes;
    }

    sendCommand() {
        this.requestReady = false
        this.receiveAck = false

        const data = this.startCellIndex.toString(16).padStart(2, "0") + this.sendData;
        const length = data.length / 2

        let packet = "AA55" // SYNC BYTE
            + this.decimalToHex(5 + length, 4) // (Header(10) ) + DataCount - (SYNC BYTE(2) + LEN(2))
            + this.decimalToHex(this.lineId, 2)
            + "0200" // CMD_LINE_DISP_LINECOMMAND
            + this.seqNum // Mode (GrapicMode: 0x00, TextMode: 0x80)
            + data;
        packet += this.checksum(this.hexToBytes(packet.substring(8)))

        return this.hexStringToArrayBuffer(packet);
    }

    /**
     * Convert a hex string to an ArrayBuffer.
     *
     * @param {string} hexString - hex representation of bytes
     * @return {ArrayBuffer} - The bytes in an ArrayBuffer.
     */
    hexStringToArrayBuffer(hexString) {
        // remove the leading 0x
        hexString = hexString.replace(/^0x/, '');

        // ensure even number of characters
        if (hexString.length % 2 !== 0) {
            console.warn('WARNING: expecting an even number of characters in the hexString');
        }

        // check for some non-hex characters
        let bad = hexString.match(/[G-Z\s]/i);
        if (bad) {
            console.warn('WARNING: found non-hex characters', bad);
        }

        // split the string into pairs of octets
        let pairs = hexString.match(/[\dA-F]{2}/gi);

        // convert the octets to integers
        let integers = pairs.map(function (s) {
            return parseInt(s, 16);
        });

        let array = new Uint8Array(integers);

        return array.buffer;
    }

    setCommand(seqNum, startCellIndex, sendData) {
        this.seqNum = seqNum;

        // 발송이 끝난상태면 새로세팅
        if (!this.requestReady && this.receiveAck) {
            this.startCellIndex = startCellIndex;
            this.sendData = sendData;
        } else {
            // 아닐경우
            // 새로등록된 시작셀이 앞일 경우
            if (this.startCellIndex >= startCellIndex) {
                const temp = this.startCellIndex - startCellIndex;

                // 기존데이터가 신규데이터와 겹칠경우
                if (temp * 2 < sendData.length) {
                    // 시작셀 차이 만큼 00 채우기(시작셀을 같게 만들어줌)
                    const tempData = "00".repeat(temp) + this.sendData;

                    // 데이터 길이 비교후 데이터 세팅(신규데이터의 길이 > 시작셀이 동일한 기존데이터의 길이)
                    // 새 데이터가 길경우 덮어쓰기 : 기존 데이터가 길경우 새 데이터 부분만큼만 덮어쓰기
                    this.sendData = sendData.length >= tempData.length ? sendData : sendData + tempData.substring(sendData.length);
                } else {
                    // 겹치지 않을경우
                    const originData = this.lineData.substring(startCellIndex * 2 + sendData.length);
                    this.sendData = sendData + originData + this.sendData;
                }

                // 시작셀 세팅
                this.startCellIndex = startCellIndex;
            } else {
                // 기존등록된 시작셀이 앞일 경우
                // 시작셀 차이 만큼 기존 데이터로 채우기
                const temp = startCellIndex - this.startCellIndex;

                // 기존데이터가 신규데이터와 겹칠경우
                if (temp * 2 < this.sendData.length) {
                    // 겹치지 않는 앞부분 + 신규데이터
                    const tempData = this.sendData.substring(0, temp * 2) + sendData;

                    // 데이터 길이 비교후 데이터 세팅
                    // 새 데이터가 길경우 덮어쓰기 : 기존 데이터가 길경우 새 데이터 뒤에 기존데이터를 붙이기
                    this.sendData = tempData.length >= this.sendData.length ? tempData : tempData + this.sendData.substring(tempData.length);
                } else {
                    // 기존데이터가 신규데이터와 겹치지 않을경우 데이터 사이에 원래 출력된 데이터로 채우기
                    const originData = this.lineData.substring(this.startCellIndex * 2);
                    this.sendData = originData + sendData;
                }
            }
        }

        this.requestReady = true;
        this.receiveAck = true;

        this.setLineData();
    }

    setLineData() {
        let tempFront = this.lineData.substring(0, this.startCellIndex * 2) + this.sendData;
        this.lineData = tempFront + this.lineData.substring(tempFront.length);

        const remainingLength = this.numberCellColumns * 2 - this.lineData.length;
        if (remainingLength > 0) {
            this.lineData += "00".repeat(remainingLength);
        }
    }
}

export {
    DotPadLine
}
